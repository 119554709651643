import React, { useState } from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(1);

  const accessToken = localStorage.getItem("accessToken");

  const handleSendOTP = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "user/forgotPassword/" + email,
      );
      toast.success("OTP sent successfully");
      setStep(2);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to send OTP");
      }
    }
  };
  
  

  const handleVerifyOTP = async () => {
    try {
      const headers = {
        // Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "user/verifyUser",
        null, // No data in the request body
      {
        params: { email, otp }, // Sending email and otp as query parameters
        headers,
      }
    );
      toast.success("OTP verified successfully");
      navigate("/resetPassword", {state:{email:email, otp:otp}});
    } catch (error: any) {
      toast.error(error.response.data.message || "Failed to verify OTP");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTP(e.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ boxShadow: "4" }}>
          <CardContent sx={{ m: 3 }}>
            <Avatar sx={{ m: "auto", bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ mt: 1, textAlign: "center" }}
            >
              {step === 1 ? "Forgot Password" : "Verify OTP"}
            </Typography>

            <Box
              component="form"
              onSubmit={(e) => e.preventDefault()}
              sx={{ mt: 1 }}
            >
              {step === 1 ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSendOTP}
                  >
                    Send OTP
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="otp"
                    label="OTP"
                    name="otp"
                    autoComplete="off"
                    autoFocus
                    value={otp}
                    onChange={handleOTPChange}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleVerifyOTP}
                  >
                    Verify OTP
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
