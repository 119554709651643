import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent, IconButton, InputAdornment, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state.email;
  const otp = location.state.otp;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const handleClickShowConfPassword = () =>
    setShowConfPassword((show) => !show);
  const [errors, setErrors] = useState<any>({
    email: null,
    password: null,
    confirmPassword: null,
  });
  const [formData, setFormData] = useState<any>({
    email: email,
    password: "",
    confirmPassword: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const validateForm = (field?: string, value?: any) => {
    let errors: any = {};
    let isValid = true;

    // Validation for Email
    if (field === "email") {
      if (!value.trim()) {
        errors.email = "Email is required";
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        errors.email = "Email is invalid";
        isValid = false;
      } else {
        errors.email = null;
      }
    }

    if (field === "password") {
      if (!value.trim()) {
        errors.password = "Password is required";
        isValid = false;
      } else if (value.trim().length < 8) {
        errors.password = "Password must be at least 8 characters long";
        isValid = false;
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
          value
        )
      ) {
        errors.password =
          "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character";
        isValid = false;
      } else {
        errors.password = null;
      }
    }

    // Validation for Confirm Password
    if (field === "confirmPassword") {
      if (!value.trim()) {
        errors.confirmPassword = "Confirm Password is required";
        isValid = false;
      } else if (value.trim() !== formData.password.trim()) {
        errors.confirmPassword = "Passwords do not match";
        isValid = false;
      } else {
        errors.confirmPassword = null;
      }
    }

    setErrors(errors);
    for (let data of Object.values(formData)) {
      console.log("data", data);
      if (!data) {
        isValid = false;
      }
    }
    return isValid;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    setIsFormValid(validateForm(name, value));
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const Password = data.get("password");
    const confirmPassword = data.get("confirmPassword");

    if (Password !== confirmPassword) {
      toast.error(`New Password and Confirm Password do not match !`, {
        autoClose: 5000,
        position: "top-right",
      });
    } else {
      try {
        const url =
          process.env.REACT_APP_SERVER_URL +
          `user/resetPassword/${formData.email}/${Password}/${otp}`;
        const res = await axios.post(
          url,
          {},
          {
            headers: {
//              Authorization: `Bearer ${localStorage.getItem('token')}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.data.success === false) {
          toast.error(res.data.message, {
            autoClose: 5000,
            position: "top-right",
          });
        } else {
          toast.success(res.data.message, {
            autoClose: 5000,
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        toast.error(
          "An error occurred while resetting password. Please try again later.",
          {
            autoClose: 5000,
            position: "top-right",
          }
        );
      }
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  useEffect(() => {
    setIsFormValid(validateForm('email', email));
  }, [])
  
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ boxShadow: "4" }}>
          <CardContent sx={{ m: 3 }}>
            <Avatar sx={{ m: "auto", bgcolor: "primary.main" }}>
              <LockResetIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ mt: 1, textAlign: "center" }}
            >
              Reset Password
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                name="email"
                id="email"
                label="Email"
                autoFocus
                value={email}
                disabled
              />
              <Grid container spacing={2}>
              <Grid item xs={6}>
              <TextField
                type={showPassword ? "text" : "password"}
                sx={{ marginTop: 2 }}
                variant="outlined"
                label="Password*"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={errors?.password}
                helperText={errors?.password ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              </Grid>
              <Grid item xs={6}>
              <TextField
                type={showConfPassword ? "text" : "password"}
                sx={{ marginTop: 2 }}
                variant="outlined"
                label="Confirm Password*"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={errors?.confirmPassword}
                helperText={errors?.confirmPassword ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isFormValid}
              >
                Submit
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ResetPassword;
